<template>
	<div class="partial partial--contact contact">
		<div class="contact__header" :style="{'background-image': 'url('+ require(`@/assets/hero_contact.jpg`) +')'}">
			<div class="contact__header-content">
			</div>
		</div>
		<div class="contact__content content">
			<div class="contact__about">
				<img src="~@/assets/photo_contact.jpg" alt="Field" />
				<h2>ABOUT US</h2>
				<p>Since 1999, Northern Star has been focused on delivering top-performing genetics for small grains and forages. We screen and select varieties from the top breeding programs around the world.  We test those varieties for local adaptation.  Our trial locations cover the Eastern United States. As varieties are advanced through trials, Northern Star tests those varieties for response to various agronomic practices. This ensures the best selection of genetics for each local situation. Northern Star believes the quality of seed is just as important as the genetics themselves. We produce seed across 22 states with very exacting standards. We pride ourselves on providing quality products and excellent, responsive customer service.</p>
			</div>
			<div class="contact__location">
				<div class="contact__location-address">
					<h3>MAIN OFFICE<br />
						<a href="https://www.google.com/maps/place/1403+County+Rd+700+W,+Frankfort,+IN+46041/@40.3074124,-86.6411039,17z" target="_blank"><address>1403 N County Road 700 W<br />
							Frankfort, IN 46041</address></a></h3>

					<h3>SEED PLANT<br />
						<span>Contact: Brandon Fitzmorris</span><br />
						<a href="tel:7654300131">(765) 430-0131</a><br />
						<br />
						<a href="https://www.google.com/maps/place/2525+500+South,+Bluffton,+IN+46714/@40.6725993,-85.2726414,17z" target="_blank"><address>2525 W. 500 South<br />
						Bluffton, IN 46714</address></a>
						</h3>
				</div>
				<div class="contact__location-map">
					<a href="https://www.google.com/maps/place/114+S+Earl+Ave,+Lafayette,+IN+47904/@40.4157113,-86.863221,17z" target="_blank"><img src="~@/assets/contact_map.png" alt="map" /></a>
				</div>
				<div class="contact__location-form">
					<!-- Begin Constant Contact Inline Form Code -->
					<div class="ctct-inline-form" data-form-id="e1b94980-303c-49a2-997f-7a8cd970e356"></div>
					<!-- End Constant Contact Inline Form Code -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'partialContact',
	mounted() {
		this.$store.dispatch('seeds/list')
	},
	metaInfo: () => ({
		title: `Contact Us`,
		script: [
			{
				innerHTML: `var _ctct_m = "6685fbcc7285499203fc3a12df3c9925";`,
				type: 'text/javascript',
				charset: 'utf-8',
			},
			{
				src: '//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js',
				async: true,
			},
		],
		__dangerouslyDisableSanitizers: ['script'],
	})
}
</script>

<style scoped lang="scss">
.contact {
	&__header {
		@include modules.ratio('custom', 238/1400);
		background-color: modules.color_('primary');
		background-position: center center;
		background-size: cover;
		border-bottom: 8px solid modules.color_('primary');

		&-content {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: modules.color_('primary', 'text');
		}
	}

	&__content {
		@include modules.gutter('column-gap',2);
		@include modules.gutter('margin-bottom', 2);
		@include modules.gutter('margin-top', 2);
		display: grid;
		grid-template-columns: 1fr 1fr;

		@include modules.media-query('phone') {
			display: flex;
			flex-direction: column-reverse;
			// grid-template-columns: 1fr;
		}
	}

	&__about {
		@include modules.fontSize(20px);
		color: modules.color_('secondary');
		line-height: 1.4;

		img {
			max-width: 100%;
		}
	}

	&__location {
		@include modules.gutter('padding');
		background-color: modules.color_('secondary');
		color: modules.color_('secondary', 'text');

		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: flex-start;

		&-address,
		&-map {
			flex: 0 1 40%;

			image {
				max-width: 100%;
			}
		}



		&-form {
			@include modules.gutter('margin-top');
			flex: 0 1 100%;
		}

		h3 {
			margin-bottom: 2rem;
			margin-top: 0;

			span {
				font-weight: 500;
			}
		}

		a {
			color: modules.color_('primary', 'text');
			font-weight: normal;
			text-decoration: none;
			transition: color 150ms;

			&:hover {
				color: modules.color_('primary');
			}
		}
	}
}
</style>
